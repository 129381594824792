import { graphql, PageProps } from "gatsby"
import cls from "classnames"
import React, { memo } from "react"
import { useWindowSize, useIntl } from "~hooks"
import { Disclosure } from "@headlessui/react"

interface article {
  title: string
  subtitle: string
  description: string[]
  options: string[]
}

interface CompetencesTemplateProps extends PageProps {
  pageContext: { slug: string }
  data: {
    markdownRemark: {
      html: string
      frontmatter: {
        articles: article[]
      }
    }
  }
}

const CompetencesTemplate: React.FC<CompetencesTemplateProps> = props => {
  const {
    data: {
      markdownRemark: { html, frontmatter },
    },
  } = props
  const { articles } = frontmatter

  const { toRender: isMobile } = useWindowSize(["mobile", "landscape"])
  const [intl] = useIntl()

  return (
    <div>
      <div className={isMobile ? "pt-[100px] text-left px-5" : "mt-[25vh]"}>
        {articles.map((article, index) => {
          if (!article) return null
          const { description, title, options, subtitle } = article
          const isHasOptions = !!options?.[0].length
          return (
            <div
              key={index}
              className={cls("text-tw-black", !isMobile && "content")}
            >


              {isMobile && <Disclosure>
                { ({ open }) => (
                  <>
                    <Disclosure.Button className="">
                      <div
                        className={cls("text-[1.125rem] flex justify-between items-center",
                          index > 0 && "mt-[40px]"
                        )}
                      >

                          <span className="text-left"> {title && intl(title) ||  subtitle && intl(subtitle)}</span>
                          <span className="text-[40px] text-[#cb4405] ">{open ? "-" : "+"}</span>

                      </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className="">
                     <>

                       {title && <div
                         className={
                           "mb-[7px] text-[1rem] text-left"
                         }
                       >
                         {subtitle && intl(subtitle)}

                       </div> }

                       <div
                         className={cls(
                           options.length > 1 && "textcols gap-x-[50px]",
                           isMobile && "grid"
                         )}
                       >
                         <div>
                           {description.map((descrip, index) => {
                             if (!descrip) return null
                             return (
                               <div
                                 key={index}
                                 className="pb-[20px] text-[0.875rem] font-content text-left"
                               >
                                 {intl(descrip)}
                               </div>
                             )
                           })}
                         </div>
                         <ul
                           className={cls(
                             isHasOptions && isMobile && "mb-[20px]  font-content"
                           )}
                         >
                           {options.map((option, index) => {
                             if (!option) return null
                             return (
                               <li
                                 key={index}
                                 className={cls(
                                   isHasOptions &&
                                   "list-disc  font-content text-[0.875rem] ml-[20px] text-left"
                                 )}
                               >
                                 {intl(option)}
                               </li>
                             )
                           })}
                         </ul>
                       </div>
                     </>
                    </Disclosure.Panel>
                    <div className="border-b border-1 pt-[20px]"/>
                  </>
                )}
              </Disclosure>}

              {!isMobile && <>
                <div
                  className={cls(
                    isMobile ? "text-[1.125rem]" : "font-bold text-[1.125rem] ",
                    index > 0 && "mt-[40px]"
                  )}
                >
                  {title && intl(title)}
                </div>

                <div
                  className={cls(
                    "mb-[7px]",
                    isMobile ? "text-[1rem]" : "text-[1rem]"
                  )}
                >
                  {subtitle && intl(subtitle)}
                </div>

                <div
                  className={cls(
                    options.length > 1 && "textcols gap-x-[50px]",
                    isMobile && "grid"
                  )}
                >
                  <div>
                    {description.map((descrip, index) => {
                      if (!descrip) return null
                      return (
                        <div
                          key={index}
                          className="pb-[20px] text-[0.875rem] font-content"
                        >
                          {intl(descrip)}
                        </div>
                      )
                    })}
                  </div>
                  <ul
                    className={cls(
                      isHasOptions && isMobile && "mb-[20px]  font-content"
                    )}
                  >
                    {options.map((option, index) => {
                      if (!option) return null
                      return (
                        <li
                          key={index}
                          className={cls(
                            isHasOptions &&
                            "list-disc  font-content text-[0.875rem] ml-[20px]"
                          )}
                        >
                          {intl(option)}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default memo(CompetencesTemplate)

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        articles {
          title
          subtitle
          description
          options
        }
      }
    }
  }
`
